import { Action } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { Phone, UserStatus } from "@app/core/auth/identity/user.reducer";
import { Colleague } from "@modules/qc-complete/components/qc-complete/reducers";
import { EmailStatus } from "@app/modules/support/models/emails";

export const supportCommonStoreName = "common";

export enum SupportCommonActionTypes {
  GET_COMPANIES = "[Support] Get Companies",
  GET_COMPANIES_SUCCESS = "[Support] Get Companies Success",
  GET_COMPANIES_ERROR = "[Support] Get Companies Error",
  GET_USERS = "[Support] Get Users",
  GET_USERS_SUCCESS = "[Support] Get Users Success",
  GET_USERS_ERROR = "[Support] Get Users Error",
  EXPORT_COMPANIES = "[Support] Export Companies",
  EXPORT_COMPANIES_SUCCESS = "[Support] Export Companies Success",
  EXPORT_COMPANIES_ERROR = "[Support] Export Companies Error",
  EXPORT_COMPANIES_CLEAN = "[Support] Export Companies Clean",
  EXPORT_USERS = "[Support] Export Users",
  EXPORT_USERS_SUCCESS = "[Support] Export Users Success",
  EXPORT_USERS_ERROR = "[Support] Export Users Error",
  EXPORT_USERS_CLEAN = "[Support] Export Users Clean",
  REPLACE_KEY_CONTACT = "[Support] Replace Key Contact",
  EDIT_USER = "[Support] Edit User",
  REPLACE_USER = "[Support] Replace User",
  SET_USER_SEARCH_DATA = "[Support] Set User search data",
  RESEND_INVITE = "[Support] Resend Invite",
  RESEND_INVITE_SUCCESS = "[Support] Resend Invite Success",
  RESEND_INVITE_ERROR = "[Support] Resend Invite Error",
  GET_DISTRIBUTOR_FM_COMPANIES = "[Support] Get Distributor FM Companies",
  GET_DISTRIBUTOR_FM_COMPANIES_SUCCESS = "[Support] Get Distributor FM Companies Success",
  GET_DISTRIBUTOR_FM_COMPANIES_ERROR = "[Support] Get Distributor FM Companies Error",
  GET_IDENTITY_USER_INFO = "[Support] Get Identity user info",
  GET_IDENTITY_USER_INFO_SUCCESS = "[Support] Get Identity user info Success",
  GET_IDENTITY_USER_INFO_ERROR = "[Support] Get Identity user info Error",
  EXTERNAL_CURATOR_PREPOPULATE = "[Support] External Currator Prepopulate",
  EXTERNAL_CURATOR_PREPOPULATE_SUCCESS = "[Support] External Currator Prepopulate Success",

  // Edit KeyContact Actions
  GET_COMPANY_USERS = "[Support] Get Company Users",
  GET_COMPANY_USERS_SUCCESS = "[Support] Get Company Users Success",
  GET_COMPANY_USERS_ERROR = "[Support] Get Company Users Error",
  ADD_COLLEAGUE = "[Support] Add Collegue",
  ADD_COLLEAGUE_ERROR = "[Support] Add Collegue Error",
  UPDATE_KEY_CONTACTS = "[Support] Update Key Contacts",
  UPDATE_KEY_CONTACTS_SUCCESS = "[Support] Update Key Contacts Success",
  UPDATE_KEY_CONTACTS_ERROR = "[Support] Update Key Contacts Error",
  FIND_USER_BY_EMAIL = "[Support] Find User By Email",
  FIND_USER_BY_EMAIL_SUCCESS = "[Support] Find User By Email Success",
  FIND_USER_BY_EMAIL_ERROR = "[Support] Find User By Email Error",
  ADMIN_UPDATE_LOCK_STATUS = "[Support] Update Lock Status",
  ADMIN_UPDATE_LOCK_STATUS_ERROR = "[Support] Update Lock Status Error",
  ADMIN_UNLOCK_USER = "[Support] Unlock User",
  ADMIN_DELETE_USER = "[Support] Delete User",
  REMOVE_USER_FROM_COMPANIES = "[Support] Remove User From Companies",

  // Email actions
  GET_EMAIL_METADATA = "[Support] Get Email Metadata",
  GET_EMAIL_METADATA_SUCCESS = "[Support] Get Email Metadata Success",
  GET_EMAIL_METADATA_ERROR = "[Support] Get Email Metadata Error",
  RESEND_EMAIL = "[Support] Resend email",
  RESEND_EMAIL_SUCCESS = "[Support] Resend email success",
  RESEND_EMAIL_ERROR = "[Support] Resend email error",
  RESEND_REMINDER = "[Support] Resend reminder",
  RESEND_REMINDER_SUCCESS = "[Support] Resend reminder success",
  RESEND_REMINDER_ERROR = "[Support] Resend reminder error",
  PAUSE_REMINDERS = "[Support] Pause reminders",
  RESUME_REMINDERS = "[Support] Resume reminders",
  ADMIN_UPDATE_PAUSE_REMINDERS_STATUS = "[Support] Update Pause reminders status",
  GET_EMAIL_DETAILS = "[Support] Get Email Details",
  GET_EMAIL_DETAILS_SUCCESS = "[Support] Get Email Details Success",
  GET_EMAIL_DETAILS_ERROR = "[Support] Get Email Details Error",

  // User actions
  CREATE_NEW_USER = "[Support] Create New User",
  CREATE_NEW_USER_SUCCESS = "[Support] Create New User Success",
  CREATE_NEW_USER_ERROR = "[Support] Create New User Error"
}

export class ActionSupportGetCompanyUsers implements Action {
  readonly type = SupportCommonActionTypes.GET_COMPANY_USERS;
  constructor(public payload: { companyId: string; isFMGuestUsers?: boolean }) {}
}

export class ActionSupportGetCompanyUsersSuccess implements Action {
  readonly type = SupportCommonActionTypes.GET_COMPANY_USERS_SUCCESS;
  constructor(public payload: { companyUsers: any }) {}
}

export class ActionSupportGetCompanyUsersError implements Action {
  readonly type = SupportCommonActionTypes.GET_COMPANY_USERS_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportAddUser implements Action {
  readonly type = SupportCommonActionTypes.ADD_COLLEAGUE;
  constructor(public payload: { companyId: string; colleague: Colleague }) {}
}

export class ActionSupportAddUserError implements Action {
  readonly type = SupportCommonActionTypes.ADD_COLLEAGUE_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportUpdateKeyContacts implements Action {
  readonly type = SupportCommonActionTypes.UPDATE_KEY_CONTACTS;
  constructor(public payload: { companyId: string; keyContacts: any }) {}
}

export class ActionSupportUpdateKeyContactsSuccess implements Action {
  readonly type = SupportCommonActionTypes.UPDATE_KEY_CONTACTS_SUCCESS;
  constructor(public payload: { response: any }) {}
}

export class ActionSupportUpdateKeyContactsError implements Action {
  readonly type = SupportCommonActionTypes.UPDATE_KEY_CONTACTS_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportFindUser implements Action {
  readonly type = SupportCommonActionTypes.FIND_USER_BY_EMAIL;
  constructor(public payload: { email: string }) {}
}

export class ActionSupportFindUserSuccess implements Action {
  readonly type = SupportCommonActionTypes.FIND_USER_BY_EMAIL_SUCCESS;
  constructor(public payload: { companyUsers: any }) {}
}

export class ActionSupportFindUserError implements Action {
  readonly type = SupportCommonActionTypes.FIND_USER_BY_EMAIL_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportGetEmailMetadata implements Action {
  readonly type = SupportCommonActionTypes.GET_EMAIL_METADATA;
  constructor(public payload: { userId: string }) {}
}

export class ActionSupportGetEmailMetadataSuccess implements Action {
  readonly type = SupportCommonActionTypes.GET_EMAIL_METADATA_SUCCESS;
  constructor(public payload: { list: EmailMetadata[] }) {}
}

export class ActionSupportGetEmailMetadataError implements Action {
  readonly type = SupportCommonActionTypes.GET_EMAIL_METADATA_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportResendEmail implements Action {
  readonly type = SupportCommonActionTypes.RESEND_EMAIL;
  constructor(public payload: { messageId: string }) {}
}

export class ActionSupportResendEmailSuccess implements Action {
  readonly type = SupportCommonActionTypes.RESEND_EMAIL_SUCCESS;
  constructor(public payload: { messageId: string }) {}
}

export class ActionSupportResendEmailError implements Action {
  readonly type = SupportCommonActionTypes.RESEND_EMAIL_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportGetCompanies implements Action {
  readonly type = SupportCommonActionTypes.GET_COMPANIES;
  constructor(
    public payload: {
      page: number;
      size: number;
      company?: string;
      status?: string;
      keyContactName?: string;
      keyContactEmail?: string;
      country?: Array<string>;
      lei?: string;
      sort?: string;
      dsc?: boolean;
    }
  ) {}
}

export class ActionSupportGetCompaniesSuccess implements Action {
  readonly type = SupportCommonActionTypes.GET_COMPANIES_SUCCESS;
  constructor(
    public payload: {
      companies: SupportCompany;
    }
  ) {}
}

export class ActionSupportGetCompaniesError implements Action {
  readonly type = SupportCommonActionTypes.GET_COMPANIES_ERROR;
  constructor(
    public payload: {
      error: any;
    }
  ) {}
}

export class ActionSupportGetUsers implements Action {
  readonly type = SupportCommonActionTypes.GET_USERS;
  constructor(
    public payload: {
      page: number;
      size: number;
      firstName?: string;
      lastName?: string;
      email?: string;
      company?: string;
      jobTitle?: string;
      departments?: string;
      status?: any;
      signupStatus?: any;
      sort?: string;
      dsc?: boolean;
    }
  ) {}
}

export class ActionSupportGetUsersSuccess implements Action {
  readonly type = SupportCommonActionTypes.GET_USERS_SUCCESS;
  constructor(
    public payload: {
      users: SupportUser;
    }
  ) {}
}

export class ActionSupportGetUsersError implements Action {
  readonly type = SupportCommonActionTypes.GET_USERS_ERROR;
  constructor(
    public payload: {
      error: any;
    }
  ) {}
}

export class ActionSupportExportCompanies {
  readonly type = SupportCommonActionTypes.EXPORT_COMPANIES;
  constructor(
    public payload: {
      country?: Array<string>;
      lei?: string;
      sort?: string;
      dsc?: boolean;
      keyContactEmail: string;
      keyContactName: string;
      company: string;
      status: string;
    }
  ) {}
}

export class ActionSupportExportUsers {
  readonly type = SupportCommonActionTypes.EXPORT_USERS;
  constructor(
    public payload: {
      firstName: string;
      lastName: string;
      email: string;
      company: string;
      status: string;
      signupStatus: string;
      jobTitle: string;
      departments: string;
    }
  ) {}
}

export class ActionReplaceKeyContact {
  readonly type = SupportCommonActionTypes.REPLACE_KEY_CONTACT;
  constructor(
    public payload: {
      companyId: string;
      keyContact: KeyContactInfo;
    }
  ) {}
}

export class ActionSupportEditUser implements Action {
  readonly type = SupportCommonActionTypes.EDIT_USER;
  public constructor(
    public payload: {
      user: object;
      id: string;
    }
  ) {}
}

export class ActionSupportReplaceUser implements Action {
  readonly type = SupportCommonActionTypes.REPLACE_USER;
  public constructor(
    public payload: {
      user: any;
      id: string;
    }
  ) {}
}

export class ActionCSVDocumentGetSuccess implements Action {
  readonly type = SupportCommonActionTypes.EXPORT_COMPANIES_SUCCESS;
  constructor(public payload: { document: any }) {}
}

export class ActionCSVDocumentGetError implements Action {
  readonly type = SupportCommonActionTypes.EXPORT_COMPANIES_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionCSVDocumentGetClean implements Action {
  readonly type = SupportCommonActionTypes.EXPORT_COMPANIES_CLEAN;
  constructor() {}
}

export class ActionCSVUserDocumentGetSuccess implements Action {
  readonly type = SupportCommonActionTypes.EXPORT_USERS_SUCCESS;
  constructor(public payload: { documentUsers: any }) {}
}

export class ActionCSVUserDocumentGetError implements Action {
  readonly type = SupportCommonActionTypes.EXPORT_USERS_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionCSVUserDocumentClean implements Action {
  readonly type = SupportCommonActionTypes.EXPORT_USERS_CLEAN;
  constructor() {}
}

export class ActionAdminUpdateLockStatus implements Action {
  readonly type = SupportCommonActionTypes.ADMIN_UPDATE_LOCK_STATUS;
  constructor(public payload: { userId: string; status: UserStatus }) {}
}

export class ActionAdminUpdateLockStatusError implements Action {
  readonly type = SupportCommonActionTypes.ADMIN_UPDATE_LOCK_STATUS;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionSupportPauseReminders implements Action {
  readonly type = SupportCommonActionTypes.PAUSE_REMINDERS;
  constructor(public payload: { companyId: string }) {}
}

export class ActionSupportResumeReminders implements Action {
  readonly type = SupportCommonActionTypes.RESUME_REMINDERS;
  constructor(public payload: { companyId: string }) {}
}

export class ActionSupportUpdatePauseRemindersStatus implements Action {
  readonly type = SupportCommonActionTypes.ADMIN_UPDATE_PAUSE_REMINDERS_STATUS;
  constructor(public payload: { companyId: string }) {}
}

export class ActionSupportUserSetSearchData implements Action {
  readonly type = SupportCommonActionTypes.SET_USER_SEARCH_DATA;
  constructor(
    public payload: {
      searchData: any;
      sortData: any;
      columns: any;
    }
  ) {}
}

export class ActionSupportUserResendInvite implements Action {
  readonly type = SupportCommonActionTypes.RESEND_INVITE;
  public constructor(
    public payload: {
      id: string;
      companyId: string;
      invitorId: string;
    }
  ) {}
}

export class ActionSupportUserResendReminderSuccess implements Action {
  readonly type = SupportCommonActionTypes.RESEND_REMINDER_SUCCESS;
  constructor() {}
}

export class ActionSupportUserResendReminderError implements Action {
  readonly type = SupportCommonActionTypes.RESEND_REMINDER_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionSupportUserResendReminder implements Action {
  readonly type = SupportCommonActionTypes.RESEND_REMINDER;
  public constructor(
    public payload: {
      userId: string;
    }
  ) {}
}

export class ActionSupportUserResendInviteSuccess implements Action {
  readonly type = SupportCommonActionTypes.RESEND_INVITE_SUCCESS;
  constructor() {}
}

export class ActionSupportUserResendInviteError implements Action {
  readonly type = SupportCommonActionTypes.RESEND_INVITE_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionSupportGetEmailDetails implements Action {
  readonly type = SupportCommonActionTypes.GET_EMAIL_DETAILS;
  constructor(public payload: { messageId: string; bucketName: string }) {}
}

export class ActionSupportGetEmailDetailsSuccess implements Action {
  readonly type = SupportCommonActionTypes.GET_EMAIL_DETAILS_SUCCESS;
  constructor(public payload: { emailDetails: any }) {}
}

export class ActionSupportGetEmailDetailsError implements Action {
  readonly type = SupportCommonActionTypes.GET_EMAIL_DETAILS_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportGetDistributorFMCompanies implements Action {
  readonly type = SupportCommonActionTypes.GET_DISTRIBUTOR_FM_COMPANIES;
  constructor(public payload: { distributorId: string }) {}
}

export class ActionSupportGetDistributorFMCompaniesSuccess implements Action {
  readonly type = SupportCommonActionTypes.GET_DISTRIBUTOR_FM_COMPANIES_SUCCESS;
  constructor(public payload: { fmCompanies: any }) {}
}

export class ActionSupportGetDistributorFMCompaniesError implements Action {
  readonly type = SupportCommonActionTypes.GET_DISTRIBUTOR_FM_COMPANIES_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionSupportUnlockUser implements Action {
  readonly type = SupportCommonActionTypes.ADMIN_UNLOCK_USER;
  public constructor(
    public payload: {
      email: string;
    }
  ) {}
}

export class ActionSupportDeleteUser implements Action {
  readonly type = SupportCommonActionTypes.ADMIN_DELETE_USER;
  public constructor(
    public payload: {
      id: string;
    }
  ) {}
}

export class ActionSupportRemoveUserFromCompanies implements Action {
  readonly type = SupportCommonActionTypes.REMOVE_USER_FROM_COMPANIES;
  public constructor(
    public payload: {
      userId: string;
      companiesIds: string[];
    }
  ) {}
}

export class ActionSupportCreateNewUser implements Action {
  readonly type = SupportCommonActionTypes.CREATE_NEW_USER;
  constructor(public payload: { user: any }) {}
}

export class ActionSupportCreateNewUserSuccess implements Action {
  readonly type = SupportCommonActionTypes.CREATE_NEW_USER_SUCCESS;
  constructor() {}
}

export class ActionSupportCreateNewUserError implements Action {
  readonly type = SupportCommonActionTypes.CREATE_NEW_USER_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ActionSupportGetIdentityUserInfo implements Action {
  readonly type = SupportCommonActionTypes.GET_IDENTITY_USER_INFO;
  constructor(public payload: { email: string }) {}
}

export class ActionSupportGetIdentityUserInfoSuccess implements Action {
  readonly type = SupportCommonActionTypes.GET_IDENTITY_USER_INFO_SUCCESS;
  constructor(public payload: { userInfo: any }) {}
}

export class ActionSupportGetIdentityUserInfoError implements Action {
  readonly type = SupportCommonActionTypes.GET_IDENTITY_USER_INFO_ERROR;
  constructor(public payload: { error: any }) {}
}

export class ActionExternalCuratorPrepopulate implements Action {
  readonly type = SupportCommonActionTypes.EXTERNAL_CURATOR_PREPOPULATE;
  constructor(public payload: { companyId: string }) {}
}

export class ActionExternalCuratorPrepopulateSuccess implements Action {
  readonly type = SupportCommonActionTypes.EXTERNAL_CURATOR_PREPOPULATE_SUCCESS;
  constructor() {}
}

export type SupportCommonAction =
  | ActionSupportGetCompanies
  | ActionSupportGetCompaniesSuccess
  | ActionSupportGetCompaniesError
  | ActionSupportGetUsers
  | ActionSupportGetUsersSuccess
  | ActionSupportGetUsersError
  | ActionSupportExportCompanies
  | ActionCSVDocumentGetSuccess
  | ActionCSVDocumentGetError
  | ActionCSVDocumentGetClean
  | ActionSupportExportUsers
  | ActionCSVUserDocumentGetSuccess
  | ActionCSVUserDocumentGetError
  | ActionCSVUserDocumentClean
  | ActionReplaceKeyContact
  | ActionSupportEditUser
  | ActionSupportReplaceUser
  | ActionSupportGetCompanyUsers
  | ActionSupportGetCompanyUsersSuccess
  | ActionSupportGetCompanyUsersError
  | ActionSupportAddUser
  | ActionSupportAddUserError
  | ActionSupportUpdateKeyContacts
  | ActionSupportUpdateKeyContactsSuccess
  | ActionSupportUpdateKeyContactsError
  | ActionSupportFindUser
  | ActionSupportFindUserSuccess
  | ActionSupportFindUserError
  | ActionAdminUpdateLockStatus
  | ActionSupportGetEmailMetadata
  | ActionSupportGetEmailMetadataSuccess
  | ActionSupportGetEmailMetadataError
  | ActionSupportResendEmail
  | ActionSupportResendEmailSuccess
  | ActionSupportResendEmailError
  | ActionSupportPauseReminders
  | ActionSupportResumeReminders
  | ActionSupportUpdatePauseRemindersStatus
  | ActionSupportUserSetSearchData
  | ActionSupportUserResendInvite
  | ActionSupportUserResendInviteSuccess
  | ActionSupportUserResendInviteError
  | ActionSupportUserResendReminder
  | ActionSupportUserResendReminderSuccess
  | ActionSupportUserResendReminderError
  | ActionSupportGetEmailDetails
  | ActionSupportGetEmailDetailsSuccess
  | ActionSupportGetEmailDetailsError
  | ActionSupportGetDistributorFMCompanies
  | ActionSupportGetDistributorFMCompaniesSuccess
  | ActionSupportGetDistributorFMCompaniesError
  | ActionSupportUnlockUser
  | ActionSupportDeleteUser
  | ActionSupportRemoveUserFromCompanies
  | ActionSupportCreateNewUser
  | ActionSupportCreateNewUserSuccess
  | ActionSupportCreateNewUserError
  | ActionSupportGetIdentityUserInfo
  | ActionSupportGetIdentityUserInfoSuccess
  | ActionSupportGetIdentityUserInfoError
  | ActionExternalCuratorPrepopulate
  | ActionExternalCuratorPrepopulateSuccess;

export const supportCompaniesSelector = (state) => state.support.common.companies;
export const supportUsersSelector = (state) => state.support.common.users;
export const supportCompanyUsersSelector = (state) => state.support.common.companyUsers;
export const supportCsvSelector = (state) => state.support.common.document;
export const supportLoading = (state) => state.support.common.loading;
export const supportCsvUsersSelector = (state) => state.support.common.documentUsers;
export const supportKeyContactsUpdatedSelector = (state) => state.support.common.keyContactsUpdated;
export const supportEmailMetadataSelector = (state) => state.support.common.emailMetadataList;
export const supportEmailDetailsSelector = (state) => state && state.support.common.emailDetails;
export const supportFMCompaniesSelector = (state) => state && state.support.common.fmCompanies;
export const supportIdentityUserInfoSelector = (state) => state && state.support.common.userInfo;

export const supportCommonInitialState: SupportCommonState = {
  loading: false,
  error: null,
  companies: null,
  users: null,
  document: null,
  documentUsers: null,
  companyUsers: null,
  keyContactsUpdated: null,
  emailMetadataList: [],
  emailDetails: null,
  fmCompanies: null
};

export function supportCommonReducer(
  state: SupportCommonState = supportCommonInitialState,
  action: SupportCommonAction
) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case SupportCommonActionTypes.GET_COMPANIES:
      return {
        ...state,
        document: null,
        documentUsers: null,
        loading: true,
        error: null
      };
    case SupportCommonActionTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.payload.companies
      };
    case SupportCommonActionTypes.GET_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportCommonActionTypes.EXPORT_COMPANIES:
      return {
        ...state,
        loading: true,
        document: null,
        error: null
      };

    case SupportCommonActionTypes.EXPORT_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        document: action.payload.document
      };
    case SupportCommonActionTypes.EXPORT_COMPANIES_CLEAN:
      return {
        ...state,
        loading: false,
        documentUsers: null,
        document: null
      };
    case SupportCommonActionTypes.EXPORT_USERS_CLEAN:
      return {
        ...state,
        loading: false,
        documentUsers: null,
        document: null
      };
    case SupportCommonActionTypes.EXPORT_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportCommonActionTypes.EXPORT_USERS:
      return {
        ...state,
        loading: true,
        documentUsers: null,
        error: null
      };
    case SupportCommonActionTypes.EXPORT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        documentUsers: action.payload.documentUsers
      };
    case SupportCommonActionTypes.EXPORT_USERS_ERROR:
      return {
        ...state,
        loading: false,
        documentUsers: null,
        error: action.payload.error
      };
    case SupportCommonActionTypes.GET_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        document: null,
        error: action.payload.error
      };
    case SupportCommonActionTypes.GET_USERS:
      return {
        ...state,
        loading: true,
        document: null,
        documentUsers: null,
        userInfo: null,
        error: null
      };
    case SupportCommonActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        document: null,
        users: action.payload.users
      };
    case SupportCommonActionTypes.GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        document: null,
        error: action.payload.error
      };

    case SupportCommonActionTypes.GET_COMPANY_USERS:
      return {
        ...state,
        loading: true,
        document: null,
        companyUsers: null,
        error: null
      };
    case SupportCommonActionTypes.GET_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        document: null,
        companyUsers: action.payload.companyUsers
      };
    case SupportCommonActionTypes.GET_COMPANY_USERS_ERROR:
      return {
        ...state,
        loading: false,
        companyUsers: null,
        document: null,
        error: action.payload.error
      };
    case SupportCommonActionTypes.REPLACE_USER: {
      return {
        ...state,
        users: {
          ...state.users,
          result: state.users.result.map((user) => {
            if (user.id !== action.payload.id) {
              return user;
            }

            return {
              ...user,
              mobile:
                action.payload.user.phones.length !== 0
                  ? action.payload.user.phones.find((phone) => phone.type === 0).number
                  : "",
              landline:
                action.payload.user.phones.length !== 0
                  ? action.payload.user.phones.find((phone) => phone.type === 1).number
                  : "",
              firstName: action.payload.user.firstName,
              lastName: action.payload.user.lastName
            };
          })
        }
      };
    }
    case SupportCommonActionTypes.FIND_USER_BY_EMAIL:
      return {
        ...state,
        loading: true,
        companyUsers: null,
        error: null
      };
    case SupportCommonActionTypes.FIND_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        companyUsers: action.payload.companyUsers,
        error: null
      };
    case SupportCommonActionTypes.FIND_USER_BY_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        companyUsers: null,
        error: action.payload.error
      };
    case SupportCommonActionTypes.ADD_COLLEAGUE:
      return {
        ...state,
        loading: true,
        companyUsers: null,
        error: null
      };
    case SupportCommonActionTypes.ADD_COLLEAGUE_ERROR:
      return {
        ...state,
        loading: false,
        companyUsers: null,
        error: action.payload.error
      };
    case SupportCommonActionTypes.UPDATE_KEY_CONTACTS:
      return {
        ...state,
        loading: true,
        keyContactsUpdated: null,
        error: null
      };
    case SupportCommonActionTypes.UPDATE_KEY_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        keyContactsUpdated: action.payload.response,
        error: null
      };
    case SupportCommonActionTypes.UPDATE_KEY_CONTACTS_ERROR:
      return {
        ...state,
        loading: false,
        keyContactsUpdated: null,
        error: action.payload.error
      };
    case SupportCommonActionTypes.ADMIN_UPDATE_LOCK_STATUS:
      return {
        ...state,
        loading: false,
        users: {
          count: state.users.count,
          result: state.users.result.map((user) => {
            if (user.id !== action.payload.userId) {
              return user;
            }
            return {
              ...user,
              isLocked: !user.isLocked,
              status: action.payload.status
            };
          })
        }
      };
    case SupportCommonActionTypes.GET_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportCommonActionTypes.GET_EMAIL_METADATA:
      return {
        ...state,
        emailMetadataList: [],
        loading: true,
        error: null
      };
    case SupportCommonActionTypes.GET_EMAIL_METADATA_SUCCESS:
      return {
        ...state,
        emailMetadataList: action.payload.list,
        loading: false,
        error: null
      };
    case SupportCommonActionTypes.GET_EMAIL_METADATA_ERROR:
      return {
        ...state,
        emailMetadataList: [],
        loading: false,
        error: action.payload.error
      };
    case SupportCommonActionTypes.ADMIN_UPDATE_PAUSE_REMINDERS_STATUS:
      return {
        ...state,
        loading: false,
        companies: {
          count: state.companies.count,
          result: state.companies.result.map((company) => {
            if (company.id !== action.payload.companyId) {
              return company;
            }
            return {
              ...company,
              isMuted: !company.isMuted
            };
          })
        }
      };
    case SupportCommonActionTypes.RESEND_INVITE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SupportCommonActionTypes.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case SupportCommonActionTypes.RESEND_INVITE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportCommonActionTypes.RESEND_REMINDER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SupportCommonActionTypes.RESEND_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case SupportCommonActionTypes.RESEND_REMINDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportCommonActionTypes.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: null,
        loading: true,
        error: null
      };
    case SupportCommonActionTypes.GET_EMAIL_DETAILS_SUCCESS:
      return {
        ...state,
        emailDetails: action.payload.emailDetails,
        loading: false,
        error: null
      };
    case SupportCommonActionTypes.GET_EMAIL_DETAILS_ERROR:
      return {
        ...state,
        emailDetails: null,
        loading: false,
        error: action.payload.error
      };
    case SupportCommonActionTypes.GET_DISTRIBUTOR_FM_COMPANIES:
      return {
        ...state,
        fmCompanies: null,
        loading: true,
        error: null
      };
    case SupportCommonActionTypes.GET_DISTRIBUTOR_FM_COMPANIES_SUCCESS:
      return {
        ...state,
        fmCompanies: action.payload.fmCompanies,
        loading: false,
        error: null
      };
    case SupportCommonActionTypes.GET_DISTRIBUTOR_FM_COMPANIES_ERROR:
      return {
        ...state,
        fmCompanies: null,
        loading: false,
        error: action.payload.error
      };
    case SupportCommonActionTypes.CREATE_NEW_USER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SupportCommonActionTypes.CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SupportCommonActionTypes.CREATE_NEW_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SupportCommonActionTypes.GET_IDENTITY_USER_INFO:
      return {
        ...state,
        userInfo: null,
        loading: true,
        error: null
      };
    case SupportCommonActionTypes.GET_IDENTITY_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload.userInfo,
        loading: false,
        error: null
      };
    case SupportCommonActionTypes.GET_DISTRIBUTOR_FM_COMPANIES_ERROR:
      return {
        ...state,
        userInfo: null,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export interface SupportCommonState {
  loading: boolean;
  error: any;
  companies: SupportCompany;
  users: SupportUser;
  companyUsers: any[];
  document: any;
  documentUsers: any;
  keyContactsUpdated: any;
  emailMetadataList: EmailMetadata[];
  emailDetails: any;
  fmCompanies: any;
}

export interface KeyContactInfo {
  email: string;
  firstName: string;
  lastName: string;
  phones: Phone[];
  department: string;
  function: string;
  role: string;
}

/**
 * Interface for document download success.
 */
export interface CSVDownloadSuccess {
  fileId: string;
  fileName: string;
  blob: Blob;
}

export interface SupportUser {
  count: number;
  result: any[];
}

export interface SupportCompany {
  count: number;
  result: any[];
}

export interface EmailMetadata {
  id: string;
  userId: string;
  subject: string;
  sender: string;
  type: string;
  resultType: string;
  interactionDate: Date;
  messageId: string;
}

export interface UserSearchData {
  searchData: {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    status: string;
    signupStatus: string;
    jobTitle: string;
    departments: string;
  };
  sortData: {
    firstName: boolean;
    lastName: boolean;
    email: boolean;
    jobTitle: boolean;
  };
  columns: any[];
}

export interface IEmailDetais {
  messageId: string;
  recipientId: string;
  recipientEmail: string;
  cc: Array<string>;
  mailFrom: string;
  date: string;
  time: string;
  subject: string;
  metaKey: string;
  body: string;
  resultType: string;
  emailType: string;
  status: EmailStatus;
}
