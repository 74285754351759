import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable } from "rxjs/internal/Observable";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  finalize,
  withLatestFrom
} from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";

import { ActionToastrSuccess, ActionToastrError, ActionToastrWarning } from "@app/core/toastr/toastr.reducer";
import { SupportService } from "@modules/support/components/support/services";
import { SupportActionTypes } from "@modules/support/components/support/reducers";
import {
  ActionUserAdminGetCompanies,
  ActionUserAdminGetCompaniesSuccess,
  ActionUserAdminSupportSessionEnd,
  ActionUserAdminSupportSessionEndSuccess,
  ActionUserAdminSupportSessionStart,
  ActionUserRetrieve,
  UserActionTypes,
  ActionUserAdminGetCompaniesError,
  ActionClear,
  selectorAdminAllCompaniesList,
  UserStatus
} from "@app/core/auth/identity/user.reducer";
import { environment } from "@env/environment";
import { ExportDdqDialogService } from "@shared/dialogs/export-ddq-dialog/export-ddq-dialog.service";
import {
  ActionSupportGetCompanies,
  ActionSupportGetCompaniesSuccess,
  ActionSupportGetCompaniesError,
  ActionSupportExportCompanies,
  ActionCSVDocumentGetError,
  ActionCSVDocumentGetSuccess,
  ActionCSVDocumentGetClean,
  ActionSupportGetUsersSuccess,
  ActionSupportGetUsersError,
  ActionSupportExportUsers,
  ActionCSVUserDocumentGetSuccess,
  ActionCSVUserDocumentGetError,
  ActionCSVUserDocumentClean,
  ActionReplaceKeyContact,
  ActionSupportGetUsers,
  ActionSupportEditUser,
  ActionSupportReplaceUser,
  ActionSupportGetCompanyUsers,
  ActionSupportGetCompanyUsersSuccess,
  ActionSupportGetCompanyUsersError,
  ActionSupportAddUser,
  ActionSupportFindUser,
  ActionSupportFindUserError,
  ActionSupportFindUserSuccess,
  ActionSupportGetEmailMetadata,
  ActionSupportGetEmailMetadataError,
  ActionSupportGetEmailMetadataSuccess,
  ActionSupportUpdateKeyContacts,
  ActionSupportResendEmail,
  ActionSupportResendEmailSuccess,
  ActionSupportResendEmailError,
  ActionSupportPauseReminders,
  ActionSupportUpdatePauseRemindersStatus,
  SupportCommonActionTypes,
  ActionSupportUserSetSearchData,
  UserSearchData,
  ActionSupportUserResendInvite,
  ActionSupportUserResendInviteSuccess,
  ActionSupportUserResendInviteError,
  ActionSupportGetEmailDetails,
  ActionSupportGetEmailDetailsSuccess,
  ActionSupportGetEmailDetailsError,
  ActionSupportGetDistributorFMCompanies,
  ActionSupportGetDistributorFMCompaniesSuccess,
  ActionSupportGetDistributorFMCompaniesError,
  ActionSupportUnlockUser,
  ActionSupportDeleteUser,
  ActionSupportRemoveUserFromCompanies,
  ActionSupportCreateNewUser,
  ActionSupportCreateNewUserSuccess,
  ActionSupportCreateNewUserError,
  ActionSupportGetIdentityUserInfo,
  ActionSupportGetIdentityUserInfoError,
  ActionSupportGetIdentityUserInfoSuccess,
  ActionExternalCuratorPrepopulate,
  ActionExternalCuratorPrepopulateSuccess,
  ActionSupportUserResendReminder,
  ActionSupportUserResendReminderSuccess
} from "../reducers/support.reducer";
import { ActionSupportDistributorSetKeyContacts } from "../reducers/distributor-companies.reducer";

@Injectable()
export class SupportEffect {
  constructor(
    private actions$: Actions<Action>,
    private exportService: ExportDdqDialogService,
    private service: SupportService,
    public readonly store: Store<any>
  ) {}

  getCompanies = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.GET_COMPANIES),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportGetCompanies) => {
        return this.service
          .getCompanies(
            action.payload.page,
            action.payload.size,
            action.payload.sort,
            action.payload.dsc,
            action.payload.company,
            action.payload.lei,
            action.payload.status,
            action.payload.country,
            action.payload.keyContactName,
            action.payload.keyContactEmail
          )
          .pipe(
            map(
              (companies) =>
                new ActionSupportGetCompaniesSuccess({
                  companies
                })
            )
          );
      }),
      catchError((error) =>
        of(
          new ActionSupportGetCompaniesError({
            error
          })
        )
      )
    );
  });

  exportCompanies = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.EXPORT_COMPANIES),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportExportCompanies) => {
        return this.service
          .exportComanies(
            action.payload.sort,
            action.payload.dsc,
            action.payload.company,
            action.payload.lei,
            action.payload.status,
            action.payload.country,
            action.payload.keyContactName,
            action.payload.keyContactEmail
          )
          .pipe(
            map(
              (document) =>
                new ActionCSVDocumentGetSuccess({
                  document
                })
            )
          );
      }),
      catchError((error) =>
        of(
          new ActionCSVDocumentGetError({
            error
          })
        )
      ),
      finalize(() => new ActionCSVDocumentGetClean())
    );
  });

  getUsers = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.GET_USERS),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportGetUsers) => {
        return this.service
          .getUsers(
            action.payload.page,
            action.payload.size,
            action.payload.sort,
            action.payload.dsc,
            action.payload.firstName,
            action.payload.lastName,
            action.payload.email,
            action.payload.company,
            action.payload.jobTitle,
            action.payload.departments,
            action.payload.status,
            action.payload.signupStatus
          )
          .pipe(
            map(
              (users) =>
                new ActionSupportGetUsersSuccess({
                  users
                })
            )
          );
      }),
      catchError((error) =>
        of(
          new ActionSupportGetUsersError({
            error
          })
        )
      )
    );
  });

  exportUsers = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.EXPORT_USERS),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportExportUsers) => {
        return this.service
          .exportUsers(
            action.payload.firstName,
            action.payload.lastName,
            action.payload.email,
            action.payload.company,
            action.payload.jobTitle,
            action.payload.departments,
            action.payload.status,
            action.payload.signupStatus
          )
          .pipe(
            map(
              (documentUsers) =>
                new ActionCSVUserDocumentGetSuccess({
                  documentUsers
                })
            )
          );
      }),
      catchError((error) =>
        of(
          new ActionCSVUserDocumentGetError({
            error
          })
        )
      ),
      finalize(() => new ActionCSVUserDocumentClean())
    );
  });

  /**
   * When REPLACE_KEY_CONTACT action is dispatched, call the service to replace key contact.
   * @memberof SupportEffect
   */
  replaceKeyContact = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.REPLACE_KEY_CONTACT),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionReplaceKeyContact) =>
        this.service.replaceKeyContact(action.payload.companyId, action.payload.keyContact).pipe(
          switchMap((colleague) => [
            new ActionToastrSuccess({
              message: "Successfully replaced key contact."
            })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              })
            )
          )
        )
      )
    );
  });

  getAllCompanies = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.ADMIN_GET_COMPANIES),
      debounceTime(500),
      distinctUntilChanged(),
      withLatestFrom(this.store.pipe(select(selectorAdminAllCompaniesList))),
      switchMap(([action, currentCompaniesList]: [ActionUserAdminGetCompanies, any]) => {
        const newPage = Math.floor(currentCompaniesList.length / action.payload.pagesize);
        return this.service.getAllCompanies(newPage, action.payload.pagesize, action.payload.query).pipe(
          map(
            (companies) =>
              new ActionUserAdminGetCompaniesSuccess({
                companies: [...currentCompaniesList, ...companies.result]
              })
          ),
          catchError((error) => of(new ActionUserAdminGetCompaniesError({ error })))
        );
      })
    );
  });

  startAdminSesstion = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.ADMIN_SUPPORT_SESSION_START),
      distinctUntilChanged(),
      switchMap((action: ActionUserAdminSupportSessionStart) => {
        return this.service.startSupportSession(action.payload.companyId, action.payload.userId).pipe(
          switchMap(() => [
            new ActionToastrSuccess({
              message: "Successfully started session"
            }),
            new ActionClear(),
            new ActionUserRetrieve({
              base_url: environment.client.base_url
            })
          ])
        );
      })
    );
  });

  endAdminSesstion = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(UserActionTypes.ADMIN_SUPPORT_SESSION_END),
      distinctUntilChanged(),
      switchMap((action: ActionUserAdminSupportSessionEnd) => {
        return this.service.endSupportSession().pipe(
          switchMap(() => [
            new ActionToastrSuccess({
              message: "Successfully stopped session"
            }),
            new ActionUserAdminSupportSessionEndSuccess(),
            new ActionClear(),
            new ActionUserRetrieve({ base_url: environment.client.base_url })
          ])
        );
      })
    );
  });

  editUser = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.EDIT_USER),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportEditUser) =>
        this.service.editUser(environment.client.base_url, action.payload && action.payload.user).pipe(
          switchMap(() => [
            new ActionSupportReplaceUser({
              user: action.payload && action.payload.user,
              id: action.payload.id
            }),
            new ActionToastrSuccess({ message: "Successfully edited." })
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error
              })
            )
          )
        )
      )
    );
  });

  getCompanyUsers = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.GET_COMPANY_USERS),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportGetCompanyUsers) =>
        this.service
          .adminGetCompanyUsers(environment.client.base_url, action.payload.companyId, action.payload.isFMGuestUsers)
          .pipe(
            map((users) =>
              users.result && users.result.length > 0
                ? new ActionSupportGetCompanyUsersSuccess({
                    companyUsers: users
                  })
                : new ActionToastrWarning({ message: "No users found!" })
            ),
            catchError((error) => of(new ActionSupportGetCompanyUsersError({ error })))
          )
      )
    );
  });

  addColeague = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.ADD_COLLEAGUE),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportAddUser) =>
        this.service
          .adminAddColleague(environment.client.base_url, action.payload.companyId, action.payload.colleague)
          .pipe(
            switchMap(() => [
              new ActionToastrSuccess({ message: "Successfully added." }),
              new ActionSupportGetCompanyUsers({
                companyId: action.payload.companyId
              })
            ]),
            catchError((error) =>
              of(
                new ActionToastrError({
                  error
                })
              )
            )
          )
      )
    );
  });

  updateKeyContacts = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.UPDATE_KEY_CONTACTS),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportUpdateKeyContacts) =>
        this.service
          .adminUpdateKeyContacts(environment.client.base_url, action.payload.companyId, action.payload.keyContacts)
          .pipe(
            switchMap((response) => [
              new ActionSupportDistributorSetKeyContacts({
                list: response,
                companyId: action.payload.companyId
              }),
              new ActionToastrSuccess({ message: "Successfully updated." })
            ]),
            catchError((error) =>
              of(
                new ActionToastrError({
                  error
                })
              )
            )
          )
      )
    );
  });

  getUsersByEmail = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.FIND_USER_BY_EMAIL),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportFindUser) =>
        this.service.adminFindUsersByEmail(environment.client.base_url, action.payload.email).pipe(
          map(
            (users) =>
              new ActionSupportFindUserSuccess({
                companyUsers: users
              })
          ),
          catchError((error) => of(new ActionSupportFindUserError({ error })))
        )
      )
    );
  });

  getUserEmailMetadata = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.GET_EMAIL_METADATA),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportGetEmailMetadata) =>
        this.service.getEmailMetadata(action.payload.userId).pipe(
          map((list) => {
            return new ActionSupportGetEmailMetadataSuccess({
              list: list
            });
          }),
          catchError((error) => of(new ActionSupportGetEmailMetadataError({ error })))
        )
      )
    );
  });

  resendEmail = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.RESEND_EMAIL),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportResendEmail) =>
        this.service.resendEmail(action.payload.messageId).pipe(
          switchMap(() => [
            new ActionSupportResendEmailSuccess({
              messageId: action.payload.messageId
            }),
            new ActionToastrSuccess({ message: "Email successfully sent." })
          ]),
          catchError((error) => of(new ActionSupportResendEmailError({ error: error })))
        )
      )
    );
  });

  resendEmailError = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.RESEND_EMAIL_ERROR),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportResendEmailError) => of(new ActionToastrError({ error: action.payload.error })))
    );
  });

  resendWeeklyReminders = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.RESEND_REMINDER),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportUserResendReminder) =>
        this.service.resendWeeklyReminder(action.payload.userId).pipe(
          switchMap(() => [
            new ActionSupportUserResendReminderSuccess(),
            new ActionToastrSuccess({
              message: "Weekly reminder successfully resended."
            })
          ]),
          catchError((error) => of(new ActionSupportUserResendInviteError({ error: error })))
        )
      )
    );
  });

  pauseReminders = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.PAUSE_REMINDERS),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportPauseReminders) =>
        this.service.pauseReminders(action.payload.companyId).pipe(
          switchMap(() => [
            new ActionSupportUpdatePauseRemindersStatus({
              companyId: action.payload.companyId
            }),
            new ActionToastrSuccess({
              message: "Reminders successfully paused."
            })
          ]),
          catchError((error) => of(new ActionToastrError({ error: error })))
        )
      )
    );
  });

  resumeReminders = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.RESUME_REMINDERS),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportPauseReminders) =>
        this.service.resumeReminders(action.payload.companyId).pipe(
          switchMap(() => [
            new ActionSupportUpdatePauseRemindersStatus({
              companyId: action.payload.companyId
            }),
            new ActionToastrSuccess({
              message: "Reminders successfully resumed."
            })
          ]),
          catchError((error) => of(new ActionToastrError({ error: error })))
        )
      )
    );
  });

  storeFilters = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SupportCommonActionTypes.SET_USER_SEARCH_DATA),
        distinctUntilChanged(),
        switchMap((action: ActionSupportUserSetSearchData) => {
          const key = "DDD-USER-FILTER";
          if (action.payload) {
            const data = action.payload;
            const unit: UserSearchData = {
              searchData: data.searchData,
              sortData: data.sortData,
              columns: data.columns
            };
            localStorage.setItem(key, JSON.stringify(unit));
          } else {
            localStorage.removeItem(key);
          }

          return new Observable();
        })
      );
    },
    { dispatch: false }
  );

  resendInvite = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportCommonActionTypes.RESEND_INVITE),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportUserResendInvite) =>
        this.service.resendInvite(action.payload.id, action.payload.companyId, action.payload.invitorId).pipe(
          switchMap(() => [
            new ActionToastrSuccess({
              message: "Successfully sent."
            }),
            new ActionSupportUserResendInviteSuccess()
          ]),
          catchError((error) => of(new ActionSupportUserResendInviteError({ error: error })))
        )
      )
    );
  });

  getUserEmailDetails = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.GET_EMAIL_DETAILS),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportGetEmailDetails) =>
        this.service.getEmailDetails(action.payload.messageId, action.payload.bucketName).pipe(
          map((emailDetails) => {
            return new ActionSupportGetEmailDetailsSuccess({
              emailDetails: emailDetails
            });
          }),
          catchError((error) => of(new ActionSupportGetEmailDetailsError({ error })))
        )
      )
    );
  });

  getDistributorFMCompanies = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.GET_DISTRIBUTOR_FM_COMPANIES),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportGetDistributorFMCompanies) =>
        this.service.getDistributorFMCompanies(action.payload.distributorId).pipe(
          map((fmCompanies) => {
            return new ActionSupportGetDistributorFMCompaniesSuccess({
              fmCompanies: fmCompanies
            });
          }),
          catchError((error) => of(new ActionSupportGetDistributorFMCompaniesError({ error })))
        )
      )
    );
  });

  unlockUser = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.ADMIN_UNLOCK_USER),
      distinctUntilChanged(),
      debounceTime(250),
      switchMap((action: ActionSupportUnlockUser) =>
        this.service.unlockUser(action.payload.email).pipe(
          switchMap(() => [
            new ActionToastrSuccess({
              message: "Successfully unlock."
            })
          ])
        )
      ),
      catchError((error) =>
        of(
          new ActionToastrError({
            error: { message: "Something went wrong, please try again later." }
          })
        )
      )
    );
  });

  deleteUser = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.ADMIN_DELETE_USER),
      distinctUntilChanged(),
      debounceTime(250),
      switchMap((action: ActionSupportDeleteUser) =>
        this.service.deleteUser(action.payload.id).pipe(
          switchMap(() => [
            new ActionToastrSuccess({
              message: "Successfully deleted."
            })
          ])
        )
      ),
      catchError((error) =>
        of(
          new ActionToastrError({
            error: { message: "Something went wrong, please try again later." }
          })
        )
      )
    );
  });

  removeUserFromCompanies = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.REMOVE_USER_FROM_COMPANIES),
      distinctUntilChanged(),
      debounceTime(250),
      switchMap((action: ActionSupportRemoveUserFromCompanies) =>
        this.service.removeUserFromCompanies(action.payload.userId, action.payload.companiesIds).pipe(
          switchMap(() => [
            new ActionToastrSuccess({
              message: "Successfully removed."
            })
          ])
        )
      ),
      catchError((error) =>
        of(
          new ActionToastrError({
            error: { message: "Something went wrong, please try again later." }
          })
        )
      )
    );
  });

  /**
   * When CREATE_NEW_USER action is dispatched, call the service to add new user.
   * @memberof SupportEffect
   */
  addNewUser = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.CREATE_NEW_USER),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportCreateNewUser) =>
        this.service.addNewUser(action.payload.user).pipe(
          switchMap((userData) => [
            new ActionToastrSuccess({
              message: "Successfully added user."
            }),
            new ActionSupportCreateNewUserSuccess()
          ]),
          catchError((error) =>
            of(
              new ActionToastrError({
                error: {
                  message: "Something went wrong, please try again later."
                }
              }),
              new ActionSupportCreateNewUserError({ error })
            )
          )
        )
      )
    );
  });

  getIdentityUserInfo = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.GET_IDENTITY_USER_INFO),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionSupportGetIdentityUserInfo) =>
        this.service.getIdentityUserInfo(action.payload.email).pipe(
          map((userInfo) => {
            return new ActionSupportGetIdentityUserInfoSuccess({
              userInfo: userInfo
            });
          }),
          catchError((error) => of(new ActionSupportGetIdentityUserInfoError({ error })))
        )
      )
    );
  });
  
  externalCuratorPrepopulate = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SupportActionTypes.EXTERNAL_CURATOR_PREPOPULATE),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((action: ActionExternalCuratorPrepopulate) =>
        this.service.externalCuratorPrepopulate(action.payload.companyId).pipe(
          switchMap(() => [
            new ActionExternalCuratorPrepopulateSuccess(),
            new ActionToastrSuccess({
              message: "Successfully populated."
            })
          ]),
          catchError((error) => of(new ActionToastrError({ error: error })))
        )
      )
    );
  });
}
